@import '../_variables.scss';

.mat-mdc-dialog-container {
  .header {
    position: relative;

    button.mat-mdc-icon-button {
      position: absolute;
      right: 0px;
      z-index: 10;
    }
  }

  .mdc-dialog {
    &__title {
      padding-top: $container-padding;
      padding-bottom: 9px;
      &::before {
        height: 0;
      }
    }
  }
  .mat-mdc-dialog-actions {
    justify-content: flex-end;
    padding: $container-padding;
    mat-spinner-button {
      margin-left: 16px;
    }

    button {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  background: #FFFFFF;
  border-radius: 2px;
  box-shadow: none;

  .mat-mdc-dialog-title {
    font-size: 20px;
    color: $text-color;
    letter-spacing: 0;
  }

  .mat-mdc-dialog-content {
    line-height: normal !important;
    font-size: $font-size !important;
    color: $text-color;
    letter-spacing: 0;
    padding: 5px 24px !important;
  }
}

.dialog {

  &.pb-0 {
    .mat-mdc-dialog-container {
      padding-bottom: 0 !important;
    }
  }

  font-size: 14px;
  color: $text-color;

  .mat-mdc-dialog-title {
    position: relative;
    font-size: 20px;
    display: flex;

    .close {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }

  a {
    color: $text-color;
    font-weight: $bold;
  }

  .mat-mdc-dialog-container {

    .mat-mdc-dialog-content {
      border-top: 1px solid #FBF9F9;
    }
  }

  .error-text {
    font-size: 12px;
    color: $error;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer {
    background-color: lighten($grey, 50);
  }
}

.milestone-dialog {
  .mat-mdc-dialog-container {
    border-radius: 0;

    .mat-mdc-dialog-content {
      padding-right: 10px !important;
    }
  }
  display: flex;
  height: 100%;
}
