@use '@angular/material' as theming;

.tag {
  border-radius: 50px;
  margin-left: 5px;
  padding: 0 5px;
  font-size: 0.60rem;
  background: map-get($light-theme-background-palette, 'selected-disabled-button');
  color: white;
  &.primary {
    color: white;
    background: map-get($kohort-primary, 400)
  }
  &-lg {
    padding: 3px 10px;
    font-size: 0.8rem;
  }
}
