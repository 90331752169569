.content-title-inner {
  @extend .mat-typography;
  font-size: 1.5rem;
  color: $text-color;
  letter-spacing: .3px;
  font-weight: bold;
  width: 100%;
  text-overflow: ellipsis;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
}

.content-title {
  &.editable-title {
    width: 100%;
  }
}
