.content-title {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 24px;
  color: $text-color;
  letter-spacing: .3px;
  width: auto;

  .content-title-icon {
    margin-left: 6px;
    color: $text-color;

    .mat-icon {
      margin-top: -4px;
      font-size: 22px;
    }
  }
}

.content-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  min-height: 40px;

  .content-header-actions {
    margin-left: auto;

    .save-settings-button {
      margin-left: 16px;
    }
  }
}

.text-grey {
  color: $darker-grey;
}

a.nav-link {
  @apply no-underline text-sm gap-4 items-center flex rounded-lg active w-full py-1.5 px-2.5 relative text-gray-700 hover:text-black dark:text-white/70 dark:hover:text-white font-normal;
  &.is-active {
    @apply bg-white dark:bg-white/10 dark:text-white shadow-sm;
    svg {
      @apply text-teal-green;
    }
  }
}
