@import '@uppy/core/dist/style.min.css';
@import '@uppy/dashboard/dist/style.min.css';
@import '@uppy/image-editor/dist/style.min.css';

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}
.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}
.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-line {
  display: none !important;
}
.cropper-view-box {
  outline: inherit !important;
}

.cropper-point.point-se {
  top: calc(85% + 1px);
  right: 14%;
}
.cropper-point.point-sw {
  top: calc(85% + 1px);
  left: 14%;
}
.cropper-point.point-nw {
  top: calc(15% - 5px);
  left: 14%;
}
.cropper-point.point-ne {
  top: calc(15% - 5px);
  right: 14%;
}

.cropper-modal {
  background-color: rgba($very-dark-grey, 75%) !important;
}

.uppy-Dashboard-inner,
.uppy-DashboardContent-bar {
  border: none;
  border-radius: unset;
  padding: 0;
}

.uppy-DashboardContent-panel {
  flex-direction: column-reverse;
  background-color: $white;
}

.uppy-ImageCropper-controls {
  padding-top: 0;
  bottom: 60px;
}

.uppy-DashboardContent-back,
.uppy-DashboardContent-save {
  border: 1px solid $link-color;
  margin: 0 !important;
  &:hover,
  &:focus {
    background-color: white;
    color: $link-color;
  }
}

.uppy-Dashboard-browse,
.uppy-DashboardContent-back,
.uppy-DashboardContent-save {
  color: $link-color;
  &:hover {
    text-decoration: none;
  }
}

.uppy-DashboardContent-save {
  background-color: $link-color;
  color: white;
}

.uppy-Dashboard-browse:focus,
.uppy-Dashboard-browse:hover {
  border-bottom: 1px solid transparent;
}

.uppy-Dashboard-AddFiles-list {
  flex: unset;
}

.uppy-dialogue-content.mat-mdc-dialog-content {
  padding: 0 !important;
}

.uppy-DashboardContent-panel,
.uppy-Dashboard-FileCard,
.uppy-Dashboard-inner,
.uppy-Dashboard-AddFiles,
.uppy-Dashboard-Item-previewInnerWrap,
.uppy-Dashboard-innerWrap {
  border-radius: 0 !important;
}
