@use '../variables';

.mat-mdc-snack-bar-container {
  min-width: auto !important;
  &.mat-snack-bar-top {
    margin-top: variables.$header-height + 10;
  }
  &.error {
    background: variables.$error;
    color: white;
    .mdc-button__label {
      color: white !important;
    }
  }

  &.success,
  &.info {
    background: variables.$notification-background;
    color: white;
    .mat-mdc-button {
      --mat-snack-bar-button-color: #FC1165;
      @include alert-button(
        variables.$notification-button-background,
        variables.$notification-button-hover-background
      );
    }
  }
}

.mat-mdc-snack-bar-label {
  text-align: center;
}

.alert {
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background: lighten(variables.$warn, 25);
  border: 1px solid lighten(variables.$red, 55);
}
