.mat-mdc-tab-header {
  --mat-tab-header-label-text-tracking: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mat-tab-header-active-label-text-color: #{variables.$title-color};
  --mat-tab-header-active-focus-label-text-color: #{variables.$title-color};
  --mat-tab-header-active-hover-label-text-color: #{variables.$title-color};
}

.input-assumptions {
  .mat-mdc-tab-labels {
    height: auto !important;
    margin-right: 10px;
    min-width: auto !important;
    text-align: center !important;
  }
}

.custom-tab-group > .mat-mdc-tab-header {

  @apply -mx-10 px-10;

  .mat-mdc-tab::after {
    content: "|";
    display: inline-block;
    color: #e6e6e6;
    padding-left: 1.25rem;
    padding-right: 1.5rem;
    padding-top: 8px;
    top: 0;
    position: relative;
    width: 1px;
    height: 100%;
    right: -4px;
    z-index: 30;
  }

  .mat-mdc-tab:last-child::after {
    content: none; /* Remove the divider after the last tab */
  }
}

.with-custom-tabs {
  /* Remove the divider after the last second last tab */
  .mat-mdc-tab:nth-last-child(2)::after {
    content: none;
  }
}

.custom-tab-nav-bar-top, .custom-tab-group > .mat-mdc-tab-header {
  transform: translateY(-1px);
}

.custom-tab-nav-bar .mat-mdc-tab-list {
  flex-grow: unset;
}

.with-custom-tabs {
  .mat-mdc-tab-label-container {
    overflow: visible;
  }

  .mat-mdc-tab.mat-mdc-tab-disabled,
  .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__content {
    opacity: 1 !important;
    pointer-events: visible !important;
    margin-left: auto;
  }

  .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label {
    opacity: 1 !important;
  }
}

.custom-tab-nav-bar, .custom-tab-group > .mat-mdc-tab-header {
  .mat-mdc-tab-links, .mat-mdc-tab-labels {
    align-items: center;
  }

  .mat-tab-link-divider::after {
    content: '|';
    display: inline-block;
    opacity: .15;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .mat-mdc-tab-link, .mat-mdc-tab {
    font-family: variables.$font-family;
    padding: 0 0.25rem;
    min-width: fit-content;
    font-size: 16px;
    font-weight: 400;

    .mdc-tab__ripple::before {
      content: unset;
    }

    &.mdc-tab--active, &.mdc-tab--active:focus {
      opacity: 1;
      font-weight: 500;

      .mdc-tab__text-label {
        --mat-tab-header-active-label-text-color: #000;
        --mat-tab-header-active-focus-label-text-color: #000;
        --mat-tab-header-active-hover-label-text-color: #000;
      }
    }
  }
}

.mat-mdc-card-content {

  .mat-mdc-tab-list {
    flex-grow: unset;
  }

}

.dark {
  .custom-tab-nav-bar-top, .custom-tab-group > .mat-mdc-tab-header {
    color: variables.$white;

    .mat-mdc-tab::after {
      color: #262a38;
    }

    .mat-mdc-tab-link, .mat-mdc-tab {
      .mdc-tab__text-label {
        --mat-tab-header-inactive-label-text-color: #fff;
        --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
        --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.8);
        opacity: 0.6;
      }

      &.mdc-tab--active, &.mdc-tab--active:focus {
        .mdc-tab__text-label {
          --mat-tab-header-active-label-text-color: #fff;
          --mat-tab-header-active-focus-label-text-color: #fff;
          --mat-tab-header-active-hover-label-text-color: rgba(255, 255, 255, 0.8);
          opacity: 1;
        }
      }
    }
  }
}

.content-tabs {

  .mdc-tab-indicator {
    display: none !important;
  }

  .mat-mdc-tab {
    .mdc-tab__content {
      .mdc-tab__text-label {
        color: variables.$grey;
      }
    }

    &.mdc-tab--active {
      .mdc-tab__text-label {
        @apply dark:text-white text-black;
      }
    }
  }
}

.pills-tab {
  .mat-mdc-tab-ripple {
    @apply rounded-full;
  }

  .mat-mdc-tab-list {
    @apply p-1 rounded-full bg-white shadow-sm border border-gray-300 flex-grow-0 mx-auto;
    .mat-mdc-tab {
      &:not(.mdc-tab--stacked) {
        height: 28px;
      }

      &.mdc-tab--active {
        .mdc-tab-indicator__content {
          @apply rounded-full active bg-digital-blue border-0 h-full;
        }

        .mdc-tab__text-label {
          @apply text-white;
        }
      }
    }
  }
}

.legacy-tabs {
  @apply h-full;

  .mat-mdc-tab-ripple {
    @apply rounded-t-xl;
  }

  .mat-mdc-tab-labels {
    @apply gap-1;
  }

  .mat-mdc-tab-body-wrapper {
    @apply h-full -z-0 -mt-[1px];
  }

  .mat-mdc-tab-list {
    .mdc-tab {
      @apply border-b-0 translate-y-[1px];
      .mdc-tab__text-label {
        @apply dark:text-white;
      }

      @apply border-solid border-gray-300/70 dark:border-slate-700/80 opacity-70 border-b-0 border hover:bg-gray-200 dark:hover:bg-slate-800 px-2 rounded-t-xl font-medium h-10 flex items-center z-10 hover:opacity-100;
      &.mdc-tab--active {
        @apply border-solid bg-white dark:border-slate-700 border-gray-300 opacity-100 dark:bg-background translate-y-[1px];
        .mdc-tab-indicator__content {
          @apply hidden;
        }

      }

      &.add-scenario-tab {
        padding: 0;
        pointer-events: all;
        opacity: 1;

        .mdc-tab__content {
          width: 100%;
          pointer-events: all;
          .mdc-tab__text-label {
            padding: 0;
          }
        }
      }
    }
  }
  .mat-mdc-tab-header-pagination-chevron {
    @apply dark:border-white border-black
  }
}

.dashboard-tabs {
  .mat-mdc-tab-header {
    width: 98%;
  }
}

@layer components {
  .modal-tabs {
    & > .mat-mdc-tab-header {
      @apply dark:bg-background bg-soft-blue;

      .mat-mdc-tab {
        &:after {
          @apply dark:bg-background bg-soft-blue;
        }
      }
    }
  }
}
