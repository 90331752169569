@use '_variables.scss';

html, body {
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.2);
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 14px;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-weight: 400;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 14px;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-select-trigger-text-size: 12px;
  --mat-paginator-form-field-container-vertical-padding: 10px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: 0;
  --mdc-filled-button-label-text-transform: none;
  --mdc-filled-button-container-height: 36px;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-label-text-tracking: 0;
  --mdc-text-button-label-text-tracking: 0;
  --mdc-text-button-container-height: 36px;
  --mdc-text-button-container-shape: 4px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-outlined-button-container-shape: 4px;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-label-text-tracking: 0;
  --mat-form-field-container-text-tracking: 0;
  --mat-select-trigger-text-tracking: 0;
  --mdc-outlined-text-field-label-text-tracking: 0;
  --mdc-form-field-label-text-tracking: 0;
  --mat-table-header-headline-tracking: 0;
  --mdc-protected-button-label-text-tracking: 0;
  --mat-divider-width: 1px;
  --mat-divider-color: #{variables.$grey-border};

  height: 100vh;
}

body {
  margin: 0;
  font-family: variables.$font-family;
  font-size: variables.$font-size;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

a {
  color: variables.$link-color;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.mat-mdc-tab-link:hover {
    text-decoration: none;
  }
}

.active:not([disabled]):active {
  transform: translate(1px, 1px);
}
.active:focus,
.inactive:focus {
  outline: 0;
}

// New UI scroll bar styles
.custom-scroll {
  &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      @apply bg-gray-300 rounded;
    }
    &::-webkit-scrollbar-thumb {
      @apply bg-gray-500 rounded;
      min-height: 20px;
      min-width: 20px;
    }
}

.dark {
  .custom-scroll {
    &::-webkit-scrollbar-track {
      background-color: variables.$scroller-background;
    }
    &::-webkit-scrollbar-thumb {
      background-color: variables.$scroller;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: variables.$primary;
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: variables.$scroller-background;
    }
  }
}

@import "mixins";
@import "layout/main";
@import "layout/helpers";
@import "layout/grid";
@import "layout/footer";
@import "components";
@import "animations";
@import "overrides";
@import "typography";
@import 'flag-icons/css/flag-icons.min.css';
