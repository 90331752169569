.segment-select {
  input {
    padding: 1rem;
    width: 100%;
    background: $light-grey;
    border-bottom: 1px solid $grey;
  }

  &.mat-mdc-select-panel {
    max-height: 50vh;
  }
}

.dropdown {
  .dropbtn {
    cursor: pointer;
  }
  display: inline-block;
  vertical-align: middle;

  .dropdown-content {
    @extend .mat-elevation-z2;
    padding: 1rem 10px;
    display: none;
    position: absolute;
    top: -100%;
    left: -10px;
    background-color: $white;
    min-width: 180px;
    z-index: 1;
    border-radius: 5px;
    min-height: auto;
    max-height: 25%;
  }

  .dropdown-content {

    div {
      color: black;
      padding: 0.5rem 0;
      text-decoration: none;
      display: block;
      &.first {
        padding-top:0;
      }
    }
  }

  /* Show the dropdown menu on hover */
  &:hover .dropdown-content {
    display: block;
  }
}
