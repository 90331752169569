@use '_variables.scss';

.mat-mdc-card {
  font-family: variables.$font-family;
  .mat-mdc-card-header {
    .mat-mdc-card-title {
      font-size: 20px;
    }
  }
}

.error-text {
  color: $error;
  font-size: 12px;
}

.no-decoration {
  display: block;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.dialog-title {
  font-size: 20px !important;
}

h6 {
  &.status-text {
    line-height: 1.5;
    margin: 0 2px 2px 0;
  }
}

.no-decoration-link {
  @extend .no-decoration;
  color: $text-color;
  &:hover {
    color: $link-color;
  }
}