.scenarios-content-wrapper {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding-bottom: 1rem;

  .content {
    &-fluid {
      @extend .container-fluid;
    }

    &:not(.content-fluid) {
      @extend .container;
    }

    flex-grow: 1;
    padding-top: $padding-top;

    .content-header {
      display: flex;
      align-items: center;
      height: 45px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        height: auto;
        align-items: start;
      }

      .content-title {
        margin: 0;
        font-size: 24px;
        color: $text-color;
        letter-spacing: .3px;
      }

      .content-header-actions {
        margin-left: auto;
        @include media-breakpoint-down(sm) {
          margin-left: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        .mat-mdc-unelevated-button {
          margin-left: 16px;
        }
      }
    }
  }
}

$sm-width: 185px;
$lg-width: 250px;
$width: 150px;
$name-width-desktop-min: 350px;
$name-width-desktop-max: $name-width-desktop-min;
$filename-width-desktop: 250px;
$product-width-desktop: 175px;
$createdAt-width-desktop-min: 150px;
$error-width-desktop: 150px;
$date-width-desktop: 130px;
$email-width-desktop: $filename-width-desktop;
$role-width-desktop: $date-width-desktop;
$eye-width-desktop: 80px;
$runtime-width-desktop-min: $eye-width-desktop;
$width-mobile: 95px;
$logo-width: 34px;
$actions-width: 120px;

.table-wrapper {
  @apply bg-white;
  &-scroll {
    overflow: auto;
  }

  .mat-column-errors {
    @media (min-width: 800px) {
      min-width: $error-width-desktop;
      max-width: $error-width-desktop;
    }
  }

  .mat-column-includeInReports, .mat-column-id {
    @media (min-width: 800px) {
      min-width: $eye-width-desktop;
      max-width: $eye-width-desktop;
    }
  }

  .mat-column-state, .mat-column-processingEnd, .mat-column-dataTo {
    @extend .mat-column-processingStart;
  }

  .mat-column-processingStart {
    @include column($width-mobile, $date-width-desktop);
  }

  .mat-column-filename {
    @include column($width-mobile, $filename-width-desktop);
  }

  .mat-column-actions {
    min-width: $actions-width;
  }

  .mat-column-logo {
    width: $logo-width;
  }

  .mat-column-name {
    @include column($width-mobile, $name-width-desktop-min);
    padding-right: 1rem;
  }

  .mat-column-created_at {
    @include column($width-mobile, $createdAt-width-desktop-min);
    padding-right: 1rem;
  }

  .mat-column-email {
    @include column($width-mobile, $email-width-desktop);
    padding-right: 1rem;
  }

  .mat-column-state, .mat-column-role, .mat-column-last_active {
    @include column($width-mobile, $role-width-desktop);
    padding-right: 1rem;
  }

  .mat-column-runtime {
    @include column($width-mobile, $runtime-width-desktop-min);
    padding-right: 1rem;
  }

  .mat-column-product {
    @media (min-width: 800px) {
      min-width: $product-width-desktop;
      max-width: $product-width-desktop;
    }

    > a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 1rem;
    }
  }

  width: 100%;

  .table-inner-wrapper {
    background: $white;
    border-radius: 3px;
    position: relative;
    width: 100%;
    overflow: auto;
  }

  table, mat-table {

    a {
      color: $text-color;

      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }

    width: 100%;
    background: transparent;

    .mat-mdc-header-row, mat-header-row {
      border: none;

      .mat-mdc-header-cell, mat-header-cell {
        border-bottom: 1px solid #f1f1f1;
        padding-left: 1rem !important;
      }
    }

    .mat-mdc-row, mat-row {
      &.ERROR {
        background: lighten($error, 55);
      }

      &.FAILED {
        background: lighten($warn, 25);
      }

      &.is-default-scenario {
        background: lighten($purple, 36);
      }

      border-bottom: none;
      transition: .1s;

      &:hover {
        border: none;
        box-shadow: 0 1px 5px rgba(0, 0, 0, .2);

        .mat-mdc-cell, mat-cell {
          border-color: $white;
        }
      }

      &.disabled-row {
        .mat-mdc-cell, mat-cell {
          color: lighten($disabled, 5);
        }
      }

      &.validating {
        opacity: .5;
        pointer-events: none;

        .mat-mdc-cell, mat-cell {
          &.state-cell {
            .state-cell-label {
              border-color: $yellow;
              color: $yellow;
            }
          }

          &.actions-cell {
            .delete-button {
              color: $text-color;
            }
          }
        }
      }

      &.processing {
        .mat-mdc-cell, mat-cell {
          &.state-cell {
            .state-cell-label {
              border-color: $blue;
              color: $blue;
            }
          }
        }
      }

      &.processed {
        .mat-mdc-cell, mat-cell {
          &.state-cell {
            .state-cell-label {
              border-color: $success;
              color: $success;
            }
          }
        }
      }

      &.outdated {
        .mat-mdc-cell, mat-cell {
          &.state-cell {
            .state-cell-label {
              border-color: $error;
              color: $error;
            }
          }
        }
      }

      .mat-mdc-cell, mat-cell {
        @include media-breakpoint-down(sm) {
          font-size: 0.8rem;
        }
        color: $text-color;
        border-bottom: 1px solid #f1f1f1;
        padding-left: 1rem !important;
        max-width: 200px;

        &.stats-cell {
          > span {
            width: 200px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.name-cell {
          font-weight: 500;

          > a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:hover {
            color: $blue;
            cursor: pointer;
          }
        }

        &.product-cell {

          &:hover {
            color: $blue;
            cursor: pointer;
          }
        }

        &.type-cell {
          text-transform: capitalize;
        }

        &.processing-end-cell {
        }

        &.state-cell {

          &.validating {
            .state-cell-inner {
              border-color: yellow;
              color: yellow;
            }
          }

          .state-cell-label {
            display: inline-block;
            align-items: center;
            padding: 5px 7px;
            font-size: 11px;
            font-weight: 500;
            border-radius: 4px;
            text-transform: uppercase;
            letter-spacing: .6px;

            .state-cell-label-inner {
              display: flex;
              align-items: center;

              svg {
                margin-right: 6px;
              }
            }
          }
        }

        &.actions-cell {
          div {
            width: 100%;
          }

          text-align: right;
           .mdc-button[disabled]:not(.mat-primary) {
            color: lighten($text-color, 50%);
          }
        }
      }
    }
  }

}

//events
.events-list, .events-list-table, .events-search-table {
  overflow: auto;
  tr {
    &.mat-mdc-row {
      height: 70px;
    }
  }

  .dense {
    tr {
      &.mat-mdc-row {
        height: 48px;
      }
    }
  }

  &.past {
    .mat-column-dau, .mat-column-monetisation, .mat-column-newUsers {
      display: none !important;
    }
  }
}

.events-list-table, .events-search-table {
  .mat-column-dates {
    width: 225px !important;
  }

  .mat-mdc-form-field:not(.mat-mdc-paginator-page-size-select) {
    width: 100% !important;
  }

  .mat-mdc-paginator-range-label {
    display: none;
  }
}

.segments-table {
  @include media-breakpoint-up(md) {
    .mat-mdc-header-row .mat-mdc-header-cell.on-sticky-start {
      padding-left: 2.25rem !important;
    }

    .mat-mdc-row .mat-mdc-cell.on-sticky-start {
      padding-left: 2.25rem !important;
    }

    .mat-mdc-header-row {
      .override-header-cell {
        max-width: 6em;
      }
    }

    .mat-mdc-row {
      .override-cell {
        max-width: 6em;
      }
    }

    .mat-column-segmentName, .mat-column-size, .mat-column-state {
      background-color: $white;
    }

    .mat-column-state {
      width: 10em !important;
    }

    .mat-column-segmentName {
      width: 35em !important;
    }

    .mat-column-size {
      width: 5em !important;
    }

  }

  .mat-column-state {
    margin-right: 5px;
  }

  .PROCESSED {
    .mat-column-messages {
      color: $blue !important;
    }
  }

  .ERROR {
    .mat-column-messages {
      background: $error !important;
    }
  }

  .FAILED {
    .mat-column-messages {
      background: $warn !important;
    }
  }

  .mat-mdc-table-sticky {
    &-border-elem-left {
      box-shadow: 3px 0 2px rgba(0, 0, 0, 0.1);
    }
  }
}

.milestones {

  table {
    width: 100%;
    @apply pb-3;
  }

  .p-4 {
    padding: 1.5rem !important;
  }
  
  .mat-mdc-row:nth-child(even) {
    background-color: lighten($very-light-grey-mostly-white, 0.70%);
  }

  .mat-form-field-invalid {
    &:has(.mat-mdc-form-field-error) {
      .mat-mdc-form-field-subscript-wrapper {
        border: 1px solid $darker-grey;
        background: $white;
        padding: 0.5rem;
        border-radius: 5px;
        z-index: 9999;
        overflow: visible;
        left: 0;
        right: 5px;    
        top: 35px;
        right: 0;
        text-wrap: pretty;
        width: max-content;
        text-wrap: pretty;
        position: absolute;

        &:before {
          position: absolute;
          top: -6px;
          left: 20px;
          content: "";
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid $darker-grey;
        }
      }
    }
  }

  .overview-table {
    .mat-mdc-form-field {
      width: 100%;
    }
  }

  th.mat-mdc-header-cell {
    border-bottom-style: none;
  }

  td.mat-mdc-cell {    
    overflow: visible;
    &:not(.mat-column-status) {
      padding-right: .5rem;
    }

    &.text-center {
      text-align: center !important;
    }

    &.text-right {
      text-align: right !important;
    }

    border-bottom-style: none;

    &.mat-column-status {
      width: 1rem;
      div {

        &.in_progress {
          background: $green;
        }

        &.inactive {
          background: $nobel;
        }

        &.not_started {
          background: rgba(0, 0, 0, 0.75);
        }
      }
    }
  }

}

table {
  @apply border-separate;
  .truncate {
    @include column($width-mobile, $width);

    &-sm {
      @include column($width-mobile, $sm-width);
    }

    &-lg {
      @include column($width-mobile, $lg-width);
    }
  }

  .column-skinny {
    .mat-mdc-form-field-infix {
      width: 55px !important;
    }
  }

  .column-narrow {
    .mat-mdc-form-field-infix {
      width: 95px !important;
    }
  }
}

.mdc-data-table__table {
  .mdc-data-table__cell, 
  .mdc-data-table__header-cell {
    padding: unset;
    text-wrap: nowrap;
  }
}