@use '../variables';

#footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:10px;
  padding-bottom: 10px;
  font-size: 12px;
  text-align: right;
}