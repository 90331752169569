@import '../_variables.scss';

.mat-mdc-card {
  color: $text-color;
  border-radius: 0.5rem !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  border: 1px solid rgb(229, 231, 235);

  .mat-mdc-card-header {
    .mat-mdc-card-header-text {
      margin: 0;

      .mat-mdc-card-title {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .mat-mdc-card-content {
    font-size: $font-size;
    .card-content-title {
      margin: 20px 0 16px;
      font-weight: 500;
    }

    .mat-divider, p {
      position: relative;
      margin: 10px 0;
    }
  }
}

.accordion-settings {
  .mat-expansion-panel-header {
    font-size: 15px !important;
    font-weight: 400 !important;
  }
  color: $text-color !important;
  .card-content-title {
    margin: 0 0 16px;
    font-weight: 500;
  }

}
