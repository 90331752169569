@use "../_variables";

ol, ul {
  list-style: revert;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  line-height: normal;
}

.sidebar-wrapper {
  a {
    text-decoration: inherit;
  }
  &-dropdown {
    a {
      color: inherit;
    }
  }
}

.mat-drawer {
  top: variables.$header-height;
}

.mat-drawer-content {
  height: auto !important;
}

.content-wrapper {
  display: flex !important;
  flex: 1 1 auto;
  justify-content: center;
  //height: $content-height;

  .container {
    padding-top: variables.$padding-top;
  }

  .content {
    &-fluid {
      @extend .container-fluid;
    }

    &:not(.content-fluid) {
      @extend .container;
    }

    flex-grow: 1;
    padding-top: variables.$padding-top;

    .content-body {
      @extend .row;
      margin-left: 0 !important;
      margin-right: 0 !important;

      .mat-mdc-card {
        width: 100%;
      }
    }
  }
}

.model-input {
  display: flex;
  align-items: baseline;

  .end-date-field {
    margin-left: 16px;
  }
}

.mat-mdc-select-panel {
 min-width: 165px;
}

.main-content {
  transition: margin-left 0.35s;
}

.inactive + div + .main-content {
  margin-left: 0 !important;
}

app-scenario-modal-header {
  @apply sticky top-0 z-10 bg-soft-blue-light/90 dark:bg-background/90;
  + router-outlet {
    + app-notification {
      top: 55px !important;
    }
  }
}
