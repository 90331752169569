@mixin column($mobile-width, $desktop-width, $wrap: true) {
  min-width: $mobile-width;
  max-width: $mobile-width;
  @include media-breakpoint-up(sm) {
    min-width: calc($desktop-width / 2);
    max-width: $desktop-width;
  }
  @if $wrap {
    white-space: nowrap;
  }
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@mixin alert-button($btn-bg, $btn-hover-bg) {
  border-color: transparent !important;
  background-color: $btn-bg;
  overflow: hidden;
  &:hover {
    background-color: $btn-hover-bg;
  }
}
