* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 120;
}