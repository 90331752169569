.panel {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  background: $white;
  @extend .mb-4;

  .mat-expansion-panel-header {
    &.mat-expanded {
      height: 56px;
    }
  }

  .align-headers {
    .mat-content {
      color: $grey;
    }

    .mat-expansion-panel-header-description {
      justify-content: end;
      align-items: center;
      margin-right: 0;
    }
  }

  .close {
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .mat-expansion-panel-body {
    padding-top: 0;
    @apply relative;
  }
}
