@use "../variables";

@mixin border-radius($radius: $border-radius) {
  border-radius: $radius;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.pa-0 {
  padding: 0 !important;
}

.position-relative {
  position: relative;
}

.p-24 {
  padding: 0 24px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb-30 {
  padding-bottom: 30px;
}

.pt-15 {
  padding-top: 15px;
}

.bg-grey {
  background: variables.$light-grey;
}

.pa-1 {
  padding: 1rem;
}

.pa-2 {
  padding: 1.5rem;
}

.pa-3 {
  padding: 2rem;
}

.w-100 {
  width: 100% !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.overflow-horizontal {
  overflow-x: auto;
}

.no-border {
  border: none !important;
}

.no-bottom-border {
  border-bottom: none !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.rounded-image {
  padding: 0;
  border-radius: 50%;
}

.rounded-avatar-small {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: variables.$very-light-grey;
  border-radius: 50%;
  background-size: cover;

  .mat-icon {
    width: 12px;
    height: 12px;
    font-size: 12px;
    margin: 0 !important;
    color: variables.$tertiary;
  }
}

.audit-info-placeholder {
  display: inline-block;
  width: 20px;
  text-align: center;
}

.break-word {
  word-break: break-word;
  padding-left: 1rem;

  li {
    margin-bottom: 5px;
  }
}

.internal-use-ribbon-top {
  border-top: solid 10px;
  border-image: repeating-linear-gradient(-75deg, variables.$primary, variables.$primary 10px, variables.$tertiary 10px, variables.$tertiary 20px) 20;
}

.bg-internal-product {
  background: repeating-linear-gradient(-25deg, variables.$primary, variables.$primary 5px, variables.$tertiary 5px, variables.$tertiary 10px);
}

.flip {
  transform: scaleY(-1);
}
