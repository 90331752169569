$white: #ffffff;
$very-dark-grey: #2F2F2F;
$very-light-grey: #ebebeb;
$very-light-grey-mostly-white: #fafafa;
$dark-grey: #686868;
$grey-tones: #B5B5B5;
$grey: #7A7A7A;
$grey-border: #e4e5e6;
$nobel: #B4B1B1;
$pink: #FC1165;
$light-pink: #fce8ef;
$red: #DA0000;
$green: #1acc86;
$blue: #294FC7;
$dark-blue: #142745;
$purple: #7256E3;
$navy-blue: #000823;
$navy-blue-75: #40465a;
$yellow: #EFB57A;
$light-grey: #FBF9F9;
$darker-grey: #858585;
$teal: #2BE8A0;

$body-background: #020617;

$font-family: "Inter", "Helvetica Neue", sans-serif;

$bold: 500;

$gutter: 10px;

$drawer-width: 272px;
$drawer-sm-width: 180px;

$header-height: 64px;
$footer-height: 32px;

$sidebar-width: 64px;

$table-cell-min-width: 250px;

$content-height: calc(100vh - 96px);

$padding-top: 0;

// dialogues/modals
$container-padding: 20px;

// brand colors
$primary: $pink;
$secondary: $blue;
$tertiary: $navy-blue;
$disabled: $dark-grey;
$info: $secondary;
$success: $green;
$error: $red;
$warn: $yellow;
$stale: $primary;

// text
$text-color: $very-dark-grey;
$title-color: $text-color;
$link-color: $primary;
$font-size: 14px;

// breadcrumbs
$breadcrumbs-background: $white;

// ribbon
$ribbon-background: $primary;
$ribbon-text: $white;

// buttons
$button-background-color: $primary;
$button-text-color: $white;

$button-background-hover: rgba($primary, 0.1);
$button-text-hover: $primary;

$button-icon-hover: $primary;
$button-icon-disabled: rgba($disabled, 0.42);

$button-outline-background-color: $primary;
$button-outline-border-color: $primary;
$button-outline-text-color: $white;

// forms
$overriden-fields: $purple;
$form-field-background: $white;

// notification
$notification-background: $secondary;
$notification-button-background: $white;
$notification-button-hover-background: $light-pink;
$notification-button-text: $primary;

// nav
$scroller: rgba($white, 0.5);
$scroller-background: $tertiary;
$nav-item-color: $white;
$nav-item-primary: $primary;
$nav-hover-background: $tertiary;

// header and sidenav
$header-background: $tertiary;
$sidebar-background: $header-background;
$header-text-color: $white;
$sidebar-text-color: $white;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

