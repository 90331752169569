:root {
  --animate-duration: 1s;
}

.spin {
  -webkit-animation: spin1 2s infinite linear;
  -moz-animation: spin1 2s infinite linear;
  -o-animation: spin1 2s infinite linear;
  -ms-animation: spin1 2s infinite linear;
  animation: spin1 2s infinite linear;
  display: block;
}
.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.mat-badge-content {
  top: -5px !important;
  right: -5px !important;
}
.new-notifications {
  .mat-badge-content {
    @extend .animated;
    @extend .bounce;
  }
}

@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes button-spinner {
  to {transform: rotate(360deg);}
}

.button-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: button-spinner .8s linear infinite;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;

  &.faster {
    animation-duration: calc(var(--animate-duration) / 2);
  }
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(10px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-3px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.fade-in-up {
  animation: fadeInUp 0.3s;
}
.fade-in-down {
  animation: fadeInDown 0.2s;
}
.fade-in-right {
  animation: fadeInRight 0.2s;
}
.fade-in-left {
  animation: fadeInLeft 0.2s;
}
.fade-in {
  animation: fadeIn 0.2s;
}
