@import "../_variables.scss";

.mat-mdc-button {
  &-base {
    letter-spacing: 0.2px;
  }
}

.mdc-button {
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: subpixel-antialiased;
  &__label {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
}

.mat-mdc-unelevated-button {
  color: $button-text-color;

  &:hover {
    background: $button-background-hover;
    color: $button-text-hover;
  }
}

.mat-mdc-outlined-button {

  &__fail, &__delete, &__cancel {
    border: 1px solid variables.$disabled !important;
    color: variables.$disabled !important;
  }

  &:not(:disabled) {
    border-color: inherit;
  }

  &:not(.state-cell-label)[disabled] {
    border-color: rgba(0, 0, 0, 0.26) !important;
    cursor: not-allowed;
  }

  &.mat-mdc-button-base:not(:disabled) {
    border-color: #2f2f2f;
  }


  &.mat-primary:not(:disabled) {
    border-color: $primary;
    color: $primary;
  }
}

.small-button {
  cursor: pointer;

  &:focus {
    outline: none;
  }

  border: none;
  background-color: transparent;
  font-size: 12px !important;
  color: $text-color;

  &:hover {
    color: $text-color;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .mat-icon {
    @extend .ml-1;
    width: auto;
    height: auto;
    font-size: 13px;
  }
}

$icon-size: 12px;

.mat-mdc-paginator-range-actions {
  button[disabled] {
    color: $button-icon-disabled !important;
  }
}

.mat-mdc-icon-button {
  &[disabled]:not(.mat-primary) {
    color: $button-icon-disabled !important;
  }

  .mat-mdc-button-persistent-ripple::before {
    content: none !important;
  }

  &:hover {
    color: $button-icon-hover;
  }

  &.small-icon-button {
    width: $icon-size;
    height: $icon-size;
    line-height: $icon-size;

    .mat-icon {
      width: $icon-size;
      height: $icon-size;
      line-height: $icon-size;
      font-size: $icon-size;
    }
  }

  &.medium-icon-button {
    --mdc-icon-button-state-layer-size: 40px;
    --mdc-icon-button-icon-size: 24px;
    // taken from current material design v17 icon button
    padding: calc(calc(var(--mdc-icon-button-state-layer-size, 48px) - var(--mdc-icon-button-icon-size, 24px)) / 2);
  }

  &.is-stale {
    color: $stale;

    &:hover {
      color: lighten($stale, 20) !important;
    }

    &:disabled {
      color: lighten($stale, 20) !important;
    }
  }
}

.mat-button-focus-overlay {
  background-color: transparent!important;
}

.mat-button-toggle-checked {
  background-color: #adadad;
}

.mat-button-toggle {
  .mat-pseudo-checkbox {
    display: none;
  }
  &-disabled {
    &.mat-button-toggle-checked {
      background-color: #ece8e8;
    }
    &.mat-button-toggle-appearance-standard {
      opacity: 0.5;
    }
  }
}

@include media-breakpoint-down(sm) {
  .state-cell-state {
    display: none !important;
  }
  .state-cell-label-inner svg {
    margin-right: 0 !important;
  }
}
