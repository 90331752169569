.mat-mdc-tooltip {
  &.events {
    background: $teal !important;
    color: black !important;
    &:after {
      content: ' EVENTS';
    }
  }
}

.mat-mdc-progress-bar {
  &.events {
      --mdc-linear-progress-active-indicator-color: #{$teal};
      --mdc-linear-progress-track-color: #{lighten($teal, 40)};

  }

  &.mat-accent {
    --mdc-linear-progress-track-color: #{lighten($grey, 50)};
  }
}
