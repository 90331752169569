@import '../_variables.scss';

mat-form-field {
  margin-top: 10px;
  position: relative;

  .mat-mdc-form-field-text-suffix {
    margin-right: -.5rem;
  }

  &.big {
    width: 300px;
  }

  &.bigger {
    width: 30%;
  }

  .mat-datepicker-toggle {
    svg {
      display: inline-block;
    }
  }
}

.card-form {
  &-group {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    min-width: fit-content;

  }

  &-inputs {
    display: flex;

    .event-button {
      &__add {
        overflow: unset;
      }
    }
  }
}

.inline-form {
  .mat-mdc-form-field, .mat-mdc-checkbox {
    margin-left: 12px;
    margin-right: 12px;
  }

  .mat-mdc-checkbox {
    margin-left: 0 !important;
  }
}

.override-form {
  margin-bottom: 1rem;

  &:after {
    content: '';
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: $grey-border;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.form-inline {
  label + mat-form-field {
    margin: 0 12px;
  }
}


.mat-mdc-form-field {
  *, ::before, ::after {
    border-style: unset;
  }

}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 20px;
}

.editable-control {
  width: 100%;
  cursor: pointer;

  &-small {
    width: auto;
    font-size: initial !important;
  }

  &.mat-mdc-input-element {
    box-sizing: border-box;
    font-size: 22px;
    margin-top: 0 !important;
    background: $form-field-background;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
  }
}

.upload-box {
  text-align: center;
  background: $white;
  border-radius: 4px;
  padding: 75px 20px;
  border: 1px dashed $darker-grey;

  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 15px rgba(200, 200, 200, .5);
  }
}

.files-list {
  .file {
    position: relative;

    .file-name {
      font-size: 12px;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg.processed {
      width: 17px;
      height: 17px;
    }
  }
}

.mat-expansion-panel {
  .mat-expansion-panel-header {
    font-weight: 500;
    font-size: 14px;
    padding: 0px 16px !important;
    color: $text-color;
  }

  .mat-expansion-panel-body {
    padding: 16px 16px;
  }
}

.settings {
  &-tabs {
    .mat-mdc-tab-header {
      margin: -16px;
      margin-bottom: unset;
    }
  }

  &-row {
    margin: 1rem 0;

    button {
      &:not(.mat-mdc-mini-fab) {
        min-width: 155px;
      }
    }
  }
}

.mat-mdc-form-field-type-mat-input, .mat-mdc-form-field-type-mat-select {
  .mat-mdc-form-field {
    &-infix {
      width: 140px !important;
    }

    &:hover:not(&-disabled) {
      .mat-mdc-floating-label, .mat-datepicker-toggle {
        color: variables.$secondary;
      }

      .mat-mdc-select {
        &-arrow {
          color: variables.$secondary;
        }
      }

      .mat-form-field-appearance-outline {
        &-start, &-end, &-gap {
          border-color: variables.$secondary;
        }
      }
    }
  }
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: fit-content;
  background: variables.$white;
  padding-right: 5px;
}

.mat-mdc-option {
  .mdc-list-item__primary-text {
    text-wrap: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40vw;
  }
}

.small-input {
  .mat-mdc-form-field-infix {
    width: 55px !important;
  }
}

.large-input {
  .mat-mdc-form-field-infix {
    width: 165px !important;
  }
}

.search-form {
  mat-label, .mat-mdc-icon-button, .mdc-checkbox__background {
    color: variables.$grey-tones;
  }
}

.inline-form-group {
  .mat-button-toggle {
    width: 100%;

    .mat-button-toggle-button {
      margin: auto;
      text-align: center;
    }

    .mat-button-toggle-label-content {
      line-height: 0;
      padding: 0.35rem 0.75rem;
    }
  }

  > * {
    display: inline-block;
  }

  .mat-mdc-form-field {
    width: 33%;

    .mat-mdc-form-field-infix {
      flex: 1;
      width: auto !important;
    }
  }

  .actions {
    text-align: right;
    float: right;
    width: 80px;
  }
}

.form-tight {
  .mat-mdc-form-field, .mat-mdc-checkbox {
    @extend .mx-2;
  }

  .auto-size {
    .mat-mdc-form-field {
      display: flex;
      flex-grow: 1;
    }
  }
}

.mat-calendar-body-cell {
  &.input-range {
    background: darken($very-light-grey-mostly-white, 5%);
  }
}

.split-button {
  .mat-button-toggle-label-content {
    padding: 12px 0.35rem !important;
    width: 100%;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background-color: $white !important;
  }
}

.split-button-menu {
  button {
    &.active {
      background-color: rgba(252, 17, 101, 0.1);
      color: $pink;
    }
  }
}

.relative {
  &_toggle {
    display: inline-flex;
    flex-direction: column;
    vertical-align: middle;
  }

  &_button {
    display: block;

    &:first-child {
      margin-bottom: 0.3em;
    }
  }
}

/** lines between the label and the control in new settings layout */
.grid-cols-6 {
  .col-span-2 {
    @apply pr-4 border-r border-gray-200
  }
}

.events-form, .list-events-form {
  .mat-mdc-form-field {
    position: relative;

    &-subscript-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
    }
  }
}
