@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/cdk/overlay-prebuilt.css';

// Ramp's CONTROL custom theme
@import 'scss/control-theme';

@import 'scss/app';